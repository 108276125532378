@keyframes dot-1 {
  0%,
  80%,
  100% {
    opacity: 1;
  }
  40%,
  60% {
    opacity: 0;
  }
}

@keyframes dot-2 {
  0%,
  60%,
  100% {
    opacity: 0;
  }
  20%,
  80% {
    opacity: 1;
  }
}

@keyframes dot-3 {
  0%,
  40%,
  100% {
    opacity: 0;
  }
  40%,
  60% {
    opacity: 1;
  }
}

.animate-dot-1 {
  animation: dot-1 1s infinite;
}

.animate-dot-2 {
  animation: dot-2 1s infinite;
  animation-delay: 0.2s;
}

.animate-dot-3 {
  animation: dot-3 1s infinite;
  animation-delay: 0.4s;
}
